import styled from "styled-components";
import { Modal } from "antd";
import settingsStyled from "../../Assets/styled/settings.styled";

export const ModalGeneralStyled = styled(Modal)`
  padding: 0;
  min-width: 770px;

  @media ${settingsStyled.m1023} {
    min-width: 100%;
    max-width: 100vw;
    min-width: 100%;
    width: 100%;
    position: absolute;
    bottom: 0px;
    top: 50px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: 0px !important;

    .ant-modal-content {
      height: 100%;
    }
  }

  .ant-modal-content {
    .ant-modal-close,
    .ant-modal-footer {
      display: none;
    }
  }
  .ant-modal-body {
    height: 100%;
    padding: 0;
  }

  &.modalDefault {
    .modalGeneral-whiteModal-container {
      background-color: #fff !important;
      padding: 0;
      margin: 0;
    }

    .ant-modal-content {
      background-color: #fff !important;
    }
  }

  &.modalContentBlue {
    .modalGeneral-whiteModal-container {
      background-color: #edf6ff !important;
      padding: 0;
    }

    .ant-modal-content {
      background-color: #edf6ff !important;
    }
  }

  &.announcements {
    .ant-modal-body,
    .ant-modal-content {
      width: 880px;
      min-height: 672.293px;
      height: auto;
      border-radius: 16px;

      @media ${settingsStyled.m1023} {
        width: 83.3vw;
        min-height: 106vw;
        margin: 0 auto;
      }
    }

    .modalGeneral {
      &-whiteModal-container {
        padding: 0;
        margin: 0;
        border-radius: 16px;

        @media ${settingsStyled.m1023} {
          div {
            margin-bottom: 4.1vw;
          }
        }
      }
    }
  }

  .modalGeneral {
    padding: 24px;

    &-title {
      ${settingsStyled.displayCenterCenter};
      background-color: ${settingsStyled.grey};

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 46px;
        color: ${settingsStyled.blue01};
        margin: 1.5rem 0;
        @include media1365 {
          font-size: 20px;
        }

        @include media767 {
          font-size: 16px;
          font-weight: 500;
        }

        @media ${settingsStyled.m479} {
          font-size: 18px;
          font-weight: 600;
          font-style: normal;
        }
      }

      &--center {
        justify-content: center;
      }

      &--right {
        justify-content: flex-end;
        margin-right: 20px;
      }

      &--left {
        justify-content: flex-start;
        margin-left: 0;
      }

      &--titleBlue {
        background-color: #d5e7f8;

        span {
          color: #005bbb;
        }
      }

      .icon {
        width: 22px;
        height: 22px;
        margin-right: 16px;
        fill: #005bbb;
      }
    }

    &-close {
      position: absolute;
      z-index: 10;
      top: -12%;
      right: -3%;
      .icon {
        cursor: pointer;
        color: #fff;
        width: 30px;

        @media ${settingsStyled.m1023} {
          width: 32px;
          height: 32px;
          fill: #005bbb;
        }
        @media ${settingsStyled.m479} {
          width: 30px;
          height: 30px;
          //  fill: ${settingsStyled.blue01};
        }
      }

      &--left {
        left: -24px;
      }

      @media ${settingsStyled.m1023} {
        background: white;
        border-radius: 50px;
        top: 29px;
        right: 24px;
      }
    }

    &--backgroundClose {
      width: 100% !important;
      .ant-modal-body,
      .ant-modal-content {
        background-color: transparent;
        box-shadow: none;
        width: 100%;
        padding: 0;
      }
    }

    &--closeBtnActive {
      .modalGeneral-whiteModal-container {
        margin-top: 20px;
      }
    }

    &-whiteModal {
      overflow: hidden;
      border-radius: 26px;
      box-shadow: 2px 12px 38px -1px rgba(0, 0, 0, 0.15);
      width: 900px !important;
      max-width: 95vw;
      max-height: 90vh;

      .ant-modal-body {
        padding: 26px 0 25px 20px;
      }

      &-container {
        ${settingsStyled.displayColumn}
        ${settingsStyled.scrollYBlue} 
        overflow-x: hidden;
        padding: 2rem;
        margin-right: 5px;
        position: relative;
        max-height: 100vh;
        min-height: 140px;
        height: 100%;
        background-color: #fff;
        &-fullHeight {
          height: 100vh;
          @media ${settingsStyled.m1023} {
            height: unset;
          }
        }
        @media ${settingsStyled.m767} {
          > div {
            margin-bottom: 100px;
          }
        }
      }
    }

    &--smallWidth {
      width: 400px !important;

      .formSubmitButton {
        .ant-row {
          margin-bottom: 0;
        }
      }
    }
  }
`;
