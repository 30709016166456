import React, { memo } from "react";
import Icon from "../icons/icon";
import PropTypes from "prop-types";
import getWordControl from "../../utils/language/getWordControl";
import { ModalGeneralStyled } from "../../../styled/partialComponents/modalGeneral.styled";

//modalStatus direkt true yollanırsa hep açık gelir

// <ModalGeneral
//  modalStatus={true}
//  className="" // "modalGeneral--smallWidth" 400px çeker
//  modalCloseControl={}
//  closeIconActive={true}
//  whiteModalActive={true}
//  sideClosing={false} // yandaki boşluklardan kapatma
//  title=""
//  titlePositon="left" // title sağda veya solda durucağını beliriyoruz "right" ve "left" alıyor. center için birşey yazma
//  closePositonLeft={true} // close sağ ve sola götürüyor
//  >

const ModalGeneral = memo(
  ({
    modalStatus,
    className,
    modalCloseControl,
    closeIconActive,
    whiteModalActive,
    sideClosing = true,
    title,
    titlePositon,
    closePositonLeft,
    children,
  }) => {
    const { GetWord } = getWordControl();
    const modalClose = () => {
      if (modalCloseControl && sideClosing) {
        modalCloseStatusControl();
      }
    };

    const closeIconControl = () => {
      if (modalCloseControl) {
        modalCloseStatusControl();
      }
    };

    const modalCloseStatusControl = () => {
      modalCloseControl();
    };

    return (
      <ModalGeneralStyled
        open={modalStatus}
        style={{}}
        className={
          "modalGeneral " +
          (closeIconActive ? "modalGeneral--closeBtnActive " : " ") +
          " " +
          (whiteModalActive
            ? "modalGeneral-whiteModal "
            : "modalGeneral--backgroundClose ") +
          " " +
          (className ? className : "")
        }
        onCancel={modalClose}
        maskClosable={closeIconActive} // dışar tıklayıp kapamayı iptal eder
      >
        {/* close iconu */}
        {closeIconActive && (
          <div
            className={
              "modalGeneral-close " +
              (closePositonLeft ? "modalGeneral-close--left " : " ")
            }
            onClick={closeIconControl}
          >
            <Icon name="icon-close" />
          </div>
        )}

        {/* titile  */}
        {title && (
          <div
            className={
              "modalGeneral-title " +
              (titlePositon === "right" ? "modalGeneral-title--right " : " ") +
              (titlePositon === "left" ? "modalGeneral-title--left " : " ")
            }
          >
            <span>{GetWord(title)}</span>
          </div>
        )}

        {whiteModalActive ? (
          <div className="modalGeneral-whiteModal-container">{children}</div>
        ) : (
          children
        )}
      </ModalGeneralStyled>
    );
  }
);

ModalGeneral.propTypes = {
  modalStatus: PropTypes.bool.isRequired,
  className: PropTypes.string,
  modalCloseControl: PropTypes.func.isRequired,
  closeIconActive: PropTypes.bool,
  whiteModalActive: PropTypes.bool,
  sideClosing: PropTypes.bool,
  title: PropTypes.string,
  titlePositon: PropTypes.string,
  closePositonLeft: PropTypes.bool,
};

export default memo(ModalGeneral);
